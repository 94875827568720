import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from '@dometech-group/dometech-packages';
// import { envImport } from '../functions';
// const { useLocalStorage } = envImport('@dometech-group/dometech-packages');
// const { useLoggedFetch } = envImport('@dometech-group/dometech-packages-redux');
import useLoggedFetch from './useLoggedFetch';

export const MESSAGE_LOADER = `<div class="ui loader active inline tiny mr-0_5"></div>`;

const useLocalFetch = ({ handleResult, firstCallPriority = true }) => {
	const initRequest = useInitLocalRequest();

	const [load, loading] = useLoggedFetch({
		handleResult,
		firstCallPriority,
		loadingIcon: MESSAGE_LOADER,
	});

	const localFetch = request => {
		console.log('load local fetch');
		load(initRequest(request));
	};

	return [localFetch, loading];
};

export const useInitLocalRequest = () => {
	const { clientId } = useSelector(state => state.user);
	const { getStoredItem } = useLocalStorage();

	return useCallback(
		(request = {}) => {
			if (!request.headers) request.headers = {};
			if (clientId) {
				request.headers['Client-Id'] = clientId;
				// request.headers['Client-Secret'] = process.env.REACT_APP_CLIENT_SECRET;
			} else {
				const storedUser = getStoredItem(process.env.REACT_APP_LOCAL_KEY);
				if (storedUser && storedUser.clientId) {
					request.headers['Client-Id'] = storedUser.clientId;
					// request.headers['Client-Secret'] = process.env.REACT_APP_CLIENT_SECRET;
				}
			}
			return request;
		},
		[clientId]
	);
};

export default useLocalFetch;
